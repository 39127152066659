/*
  This is a controller used to expand and collapse content. It is re-run on window-resize (debounced).
  It expects the following markup:
    [data-expandable]
      - Container element to run the functionality on. Every other element is a child of this element.
      - This element can have the --expandable-height css variable set on it to set the height of the content element.
      - Without proper css classes (setting the max-height of the content element to --expandable-height) this code will not function correctly.
    [data-expandable-activator]
      - Button to to expand/collapse the content.
      - This element has another property of data-toggle-content which has the value to change its content when expanded/closed.
      - Traditionally it is a More/Less text button.
    [data-expandable-content]
      - Element to expand/collapse.
      - This element can have a [data-expandable-height] which contains a pixel value to decide when it should be expanded.
    [data-expandable-match-height]
      - This must be a child element of the content element.
      - If it is present, the content element will be set to the same height as this element.
      - It can also have an optional [data-offset] value to add an additional amount of padding
 */
(function () {
  const updateHeight = (contentEl, activatorEl, wrapperEl) => {
    let maxHeight = 40; // default
    const childToMatch = contentEl.querySelector('[data-expandable-match-height]');
    if (contentEl.dataset.expandableHeight) {
      maxHeight = parseInt(contentEl.dataset.expandableHeight);
    } else if (childToMatch) {
      const offset = childToMatch.dataset.offset ? parseInt(childToMatch.dataset.offset) : 0;
      maxHeight = childToMatch.getBoundingClientRect().height + offset;
      wrapperEl.style = `--expandable-height: ${maxHeight}px`;
    }
    if (contentEl.scrollHeight > maxHeight) {
      activatorEl.classList.remove('hide');
    } else {
      activatorEl.classList.add('hide');
    }
  }
    ;

  const calculateHeights = () => {
    document.querySelectorAll('[data-expandable]').forEach(el => {
      const wrapperEl = el;
      const contentEl = el.querySelector('[data-expandable-content]');
      const activatorEl = el.querySelector('[data-expandable-activator]');
      updateHeight(contentEl, activatorEl, wrapperEl);
    });
  }

  calculateHeights();

  document.querySelectorAll('[data-expandable]').forEach(el => {
    const contentEl = el.querySelector('[data-expandable-content]');
    const activatorEl = el.querySelector('[data-expandable-activator]');
    activatorEl.addEventListener('click', (e) => {
      e.stopPropagation();
      el.classList.toggle('container-expanded');
      contentEl.classList.toggle('expanded');
      const newContent = activatorEl.dataset.toggleContent;
      activatorEl.dataset.toggleContent = activatorEl.innerText;
      activatorEl.innerText = newContent;
    })
  });

  let timeout;
  window.addEventListener('resize', () => {
    // clear the timeout
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(calculateHeights, 250);
  });
  window.addEventListener('custom_event_1', () => {
    // clear the timeout
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(calculateHeights, 250);
  });
})()